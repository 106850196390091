import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from "react-bootstrap";
import Icon1 from "../../img/Icon1.png";
import Icon2 from "../../img/Icon2.png";
import Icon3 from "../../img/Icon3.png";
import Icon4 from "../../img/Icon4.png";
import BottomNav from '../../layouts/BottomNav';
import useReactRouter from "use-react-router";
import { ABSENCE, APPROVE, HISTORY_ABSENCE } from '../../routes/app';
import { useLazyQuery } from '@apollo/client';
import TotalDay from './Component/TotalDay';
import { getStaffLogin } from '../../helper';
import Schema from './Apollo';

export default function Menu() {
    const { history } = useReactRouter();
    const user = getStaffLogin();
    const position = user?.position;
    const [data, setData] = useState([]);
    const [queryVacationDay, { data: result, loading }] = useLazyQuery(Schema.QUERY_VACATION_DAY, {
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        queryVacationDay({
            variables: {
                where: {

                },
            },
        });
    }, []);

    useEffect(() => {
        setData(result?.vacationDay?.data);
    }, [result]);

    return (
        <div
            id="appCapsule"
            style={{
                backgroundColor: "#400a36",
                marginBottom: 0,
                paddingBottom: 0,
            }}
        >
            <div className="appHeader text-light border-0 text-right">
                <div style={{ flex: 1 }} className="text-left text-center">
                    ເມນູ
                </div>
            </div>
            <div className="body-content body-content-lg" style={{ backgroundColor: "#FFFFFF" }} >
                <div className="container" style={{ height: "100vh" }}>
                    <Card className="p-2" style={{ marginTop: '-140px', height: '150px', border: '2px solid #E4E1E5' }}>
                        <div className='text-center'><h3>ວັນລາພັກທີ່ຍັງເຫຼືອ</h3></div>
                        <Row className='mt-2'>
                            <Col className='col-6'>
                                {data?.slice(0, 2).map((item, index) => (
                                    <div key={index}>
                                        <TotalDay item={item} />
                                    </div>
                                ))}
                            </Col>
                            <Col className='col-6'>
                                {data?.slice(2, 3).map((item, index) => (
                                    <div key={index}>
                                        <TotalDay item={item} />
                                    </div>
                                ))}
                                {user?.gender === "FEMALE" && (
                                    <>
                                        {data?.slice(3, 4).map((item, index) => (
                                            <div key={index}>
                                                <TotalDay item={item} />
                                            </div>
                                        ))}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card>
                    <div className='mb-2 mt-3'>
                        <h4>ເມນູຫຼັກ</h4>
                    </div>
                    <Row >
                        <span className="col-3 d-flex justify-content-center">
                            <Card
                                style={{
                                    borderRadius: "25px",
                                    width: "70px",
                                    height: "70px",
                                    transition: "transform 0.2s",
                                    cursor: "pointer",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                }}
                                onMouseDown={(event) => (event.currentTarget.style.transform = "scale(0.95)")}
                                onMouseUp={(event) => (event.currentTarget.style.transform = "scale(1)")}
                                onMouseLeave={(event) => (event.currentTarget.style.transform = "scale(1)")}
                                onClick={() => history.push(`${ABSENCE}`)}
                            >
                                <div className="text-center align-items-center p-1">
                                    <img src={Icon1} alt={"LOGO"} style={{ height: "20px", width: "20px" }} />
                                </div>
                                <div className="text-center align-items-center">
                                    <h6>ໃບລາພັກ</h6>
                                </div>
                            </Card>
                        </span>
                        <span className="col-3 d-flex justify-content-center">
                            <Card
                                style={{
                                    borderRadius: "25px",
                                    width: "70px",
                                    height: "70px",
                                    transition: "transform 0.2s",
                                    cursor: "pointer",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                }}
                                onMouseDown={(event) => (event.currentTarget.style.transform = "scale(0.95)")}
                                onMouseUp={(event) => (event.currentTarget.style.transform = "scale(1)")}
                                onMouseLeave={(event) => (event.currentTarget.style.transform = "scale(1)")}
                                onClick={() => history.push(`${HISTORY_ABSENCE}`)}
                            >
                                <div className="text-center align-items-center p-1">
                                    <img src={Icon2} alt={"LOGO"} style={{ height: "20px", width: "20px" }} />
                                </div>
                                <div className="text-center align-items-center">
                                    <h6>ປະຫວັດການລາພັກ</h6>
                                </div>
                            </Card>
                        </span>
                        {/* ຢ່າລືມເອົາ id 21. id 11 ອອກ */}
                        {[9, 10, 11, 29, 38, 21].includes(position?._id) && (
                            <span className="col-3 d-flex justify-content-center">
                                <Card
                                    style={{
                                        borderRadius: "25px",
                                        width: "70px",
                                        height: "70px",
                                        transition: "transform 0.2s",
                                        cursor: "pointer",
                                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                    }}
                                    onMouseDown={(event) => (event.currentTarget.style.transform = "scale(0.95)")}
                                    onMouseUp={(event) => (event.currentTarget.style.transform = "scale(1)")}
                                    onMouseLeave={(event) => (event.currentTarget.style.transform = "scale(1)")}
                                    onClick={() => history.push(`${APPROVE}`)}
                                >
                                    <div className="text-center align-items-center p-1">
                                        <img src={Icon3} alt={"LOGO"} style={{ height: "20px", width: "20px" }} />
                                    </div>
                                    <div className="text-center align-items-center">
                                        <h6>ອະນຸມັດ</h6>
                                    </div>
                                </Card>
                            </span>
                        )}
                        {/* <span className="col-3 d-flex justify-content-center">
                            <Card
                                style={{
                                    borderRadius: "25px",
                                    width: "70px",
                                    height: "70px",
                                    transition: "transform 0.2s",
                                    cursor: "pointer",
                                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                                }}
                                onMouseDown={(event) => (event.currentTarget.style.transform = "scale(0.95)")}
                                onMouseUp={(event) => (event.currentTarget.style.transform = "scale(1)")}
                                onMouseLeave={(event) => (event.currentTarget.style.transform = "scale(1)")}
                            >
                                <div className="text-center align-items-center p-1">
                                    <img src={Icon4} alt={"LOGO"} style={{ height: "20px", width: "20px" }} />
                                </div>
                                <div className="text-center align-items-center">
                                    <h6>ເບີກເງີນລວງໜ້າ</h6>
                                </div>
                            </Card>
                        </span> */}
                    </Row>
                </div>
                <div className='text-center'>
                    <i>DESIGN BY ANOUSITH TECHNOLOGY</i>
                </div>
                <BottomNav />
            </div>
        </div>
    );
}