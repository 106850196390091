import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { getStaffLogin } from '../../../helper';
import _, { sumBy } from 'lodash';
import Schema from '../Apollo';

export default function TotalDay({ item }) {
    const [data, setData] = useState([]);
    const user = getStaffLogin();
    const [queryVacation, { data: result, loading }] = useLazyQuery(Schema.QUERY_VACATION, {
        fetchPolicy: "cache-and-network",
    });

    useEffect(() => {
        queryVacation({
            variables: {
                where: {
                    createBy: Number(user._id),
                    vacationId: parseInt(item._id),
                    isApproved: "COMPLETE",
                },
            },
        });
    }, []);

    useEffect(() => {
        setData(result?.vacation?.data);
    }, [result]);


    const totalDay = _.sumBy(data, "isDay");
    const totalVacation = item.totalVacation - totalDay;
    const title = item.title;
    return (
        <div>
            <h3>{title}<span className='text-danger'> {totalVacation}</span> ວັນ</h3>
        </div>
    )
}