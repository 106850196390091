import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import HomePage from "../pages/home/index";
import Login from "../pages/login/Login";
import { TOKEN, TOKEN_OTP } from "../helper";
import * as ROUTES from "./app";
import TabMenuBarHistory from "../pages/history/index";
import Profile from "../pages/profile/Profile";
import DetailItemMoney from "../pages/history/DetailItemMoney";
import VerifyLogin from "../pages/login/VerifyLogin";
import PublicRoute from "./PublicRoute";
import Menu from "../pages/menu/Index";
import Absence from "../pages/menu/Absence";
import History_Absence from "../pages/menu/HistoryAbsence";
import Approve from "../pages/menu/Approve";

function Routes() {
  const otpKey = localStorage.getItem(TOKEN_OTP);
  const _local = localStorage.getItem(TOKEN);
  return (
    <Router>
      <Switch>
        {!otpKey ? (
          <React.Fragment>
            <Route path="/">
              <Redirect to="/login" />
            </Route>
            <Route exact path="/login" component={Login} />
          </React.Fragment>
        ) : otpKey && !_local ? (
          <>
            <React.Fragment>
              <Route path="/">
                <Redirect to="/verify" />
              </Route>
              <PublicRoute exact  path="/verify" component={VerifyLogin} />
            </React.Fragment>
          </>
        ) : (
          <React.Fragment>
            <Route
              render={({ location, history }) => (
                <React.Fragment>
                  <Route exact path="/login" />
                  <Route exact path="/">
                    <Redirect to="/home" />
                  </Route>
                  <Route exact path={`${ROUTES.HISTORY}/:confirm`} component={(props) => <TabMenuBarHistory {...props} />} />
                  <Route exact path={`${ROUTES.DETAIL_MONEY}/:_id`} component={(props) => <DetailItemMoney {...props} />} />
                  <Route exact path={`${ROUTES.PROFILES}`} component={(props) => <Profile {...props} />} />
                  <Route exact path={`${ROUTES.MENU}`} component={(props) => <Menu {...props} />} />
                  <Route exact path={`${ROUTES.ABSENCE}`} component={(props) => <Absence {...props} />} />
                  <Route exact path={`${ROUTES.HISTORY_ABSENCE}`} component={(props) => <History_Absence {...props} />} />
                  <Route exact path={`${ROUTES.APPROVE}`} component={(props) => <Approve {...props} />} />
                  <Route
                    render={({ location, history }) => (
                      <>
                        <PrivateRoute path={"/home"} exact component={(props) => <HomePage />} />
                      </>
                    )}
                  />
                </React.Fragment>
              )}
            />
          </React.Fragment>
        )}
      </Switch>
    </Router>
  );
}
export default Routes;
