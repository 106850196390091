import { gql } from "@apollo/client";

export default class Schema {
  static QUERY_VACATION = gql`
    query Query($where: VacationWhereInput, $orderBy: VacationOrderBy, $skip: Int, $limit: Int) {
        vacation(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
          total
          data {
            _id
            vacationId {
              _id
              title
              totalVacation
            }
            isDay
            detail
            approveBy {
              _id
              firstName
              lastName
            }
            createDate
            isApproved
            approveDate
            department {
              _id
              title_lao
              title_eng
            }
            createBy {
              _id
              firstName
              lastName
              workStartDate
            }
            startDate
            image
            endDate
            remark
          }
        }
      }
  `;

  static QUERY_VACATION_DAY = gql`
  query Query($where: VacationDayWhereInput, $orderBy: VacationDayOrderBy, $skip: Int, $limit: Int) {
    vacationDay(where: $where, orderBy: $orderBy, skip: $skip, limit: $limit) {
      total
      data {
        _id
        title
        totalVacation
        type
      }
    }
  }
  `;

  static CREATE_VACATION = gql`
    mutation Mutation($data: VacationInput!) {
      createVacation(data: $data) {
        _id
      }
    }
  `;

  static UPDATE_VACATION = gql`
  mutation Mutation($data: VacationInput!, $where: WhereById!) {
    updateVacation(data: $data, where: $where) {
      _id
    }
  }
  `;

  static DELETE_VACATION = gql`
  mutation Mutation($where: WhereById!) {
    deleteVacation(where: $where) {
      _id
    }
  }
  `;
}