import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import Notiflix, { Report } from "notiflix";
import { clearLocalStorage, TOKEN } from "../helper";

// const api = "http://localhost:5000/graphql";
const api = "https://api.dev.sameday.anousith.express/graphql";

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (networkError?.message === "Failed to fetch") {
    Report.warning(
      "ແຈ້ງເຕືອນ",
      "ມີຂໍ້ຜິດພາດເກີດຂື້ນ. ກະລຸນາລອງໃໝ່ອີກຄັ້ງ.",
      "ຕົກລົງ"
    );
    return;
  }

  if (!graphQLErrors?.length) return;
  const _error = graphQLErrors[0];

  if (_error === "UNAUTHORIZED") {
    Notiflix.Report.warning(
      "ແຈ້ງເຕືອນ",
      "ການເຂົ້າລະບົບຂອງທ່ານໄດ້ໝົດອາຍຸແລ້ວ ກະລຸນາເຂົ້າລະບົບໃໝ່ອິດຄັ້ງ",
      "ຕົກລົງ",
      async () => {
        Notiflix.Loading.standard("ກຳລັງດຳເນິນງານ...");
        clearLocalStorage();
        window.location.replace("/login");
      }
    );
  }

  if (_error?.message === "Error: JsonWebTokenError: jwt must be provided") {
    localStorage.clear();
    window.location.replace("/");
  }
  
  if (_error?.message === "Error: JsonWebTokenError: invalid signature") {
    localStorage.clear();
    window.location.replace("/");
  }
});

const client = new ApolloClient({
  link: from([
    errorLink,
    authLink.concat(
      createHttpLink({
        uri: api,
      })
    ),
  ]),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

export default function ApolloClientProvider(props) {
  return (
    <>
      <ApolloProvider client={client}>{props.children}</ApolloProvider>
    </>
  );
}
